
import React from 'react';
import { FilledInput, Box } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import { useState } from 'react';

function Input(props) {

    const addChatItem = props.addChatItem;
    const handleWaiting = props.handleWaiting;

    const [question, setQuestion] = useState('');

    function handleChange(e) {
        setQuestion(e.target.value);
    }

    const handleSubmit = () => {

        addChatItem(question, true);
        handleWaiting(true);
        setQuestion("");

        fetch('https://summon-gpt-server.herokuapp.com/faq', {
        //fetch('http://172.16.16.210:3010/faq', {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "prompt": question
            }),
        }).then(res => {
            if (res.status !== 200 && res.status !== 201) {
                handleWaiting(false);
                addChatItem("Sorry, something went wrong. Please try again in a couple of minutes. Or you can drop us an email: hello@summon.com.au", false);
                throw new Error('Failed!');
            }
            return res.json();
        })
            .then(resData => {
                if (resData.choices.length === 0) {
                    handleWaiting(false);
                    addChatItem("Sorry, something went wrong. Please try again in a couple of minutes. Or you can drop us an email: hello@summon.com.au", false);
                    return;
                } else {
                    handleWaiting(false);
                    addChatItem(resData.choices[0].text, false);
                    
                }
                
            })
            .catch(err => {
                console.log(err);
            });


    }



    return (

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 5 }}>
            <FilledInput sx={{ width: '100%', maxWidth: 500, backgroundColor: '#ffffff', boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, .1)' }}
                onChange={handleChange} id="outlined-basic" variant="outlined" outlined="false"
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                placeholder='Ask a question'
                value={question}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="send"
                            onClick={handleSubmit}
                        >
                            <SendIcon />
                        </IconButton>
                    </InputAdornment>}
            />
        </Box>
    );
}

export default Input;


