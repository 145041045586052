import './App.css';
import { Box } from '@mui/material';
import Input from './components/input';
import { useState } from 'react';
import { useEffect, useRef } from 'react';
import { Skeleton } from '@mui/material';


function App() {

  const [qanda, setQanda] = useState([["Hi, how can I help you? You can ask me any questions about our service.", false]]);
  const [isWaiting, setIsWaiting] = useState(false);

  const divRef = useRef(null);


  useEffect(() => {
    divRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [qanda]);


  const addChatItem = (text, isQuestion) => {
    setQanda(prevQanda => {
      return [...prevQanda, [text, isQuestion]];
    });
  };

  const handleWaiting = (isWaiting) => {
    setIsWaiting(isWaiting);
  };


  const chatItems = qanda.map((item, index) => {
    if (item[1] === true) {
      return (
        <p key={index} className='from-me'>{item[0]}</p>
      );
    } else {
      return (
        <p key={index} className='from-them'>{item[0]}</p>
      );
    }
  });



  return (
    <div className="App">

      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingBottom: '100px'}}>
        <h1>Summon Chatbot</h1>
  
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '5px' }}>
          <div className='imessage'>
            {chatItems}
            {isWaiting ? <p className='from-them'>
                            <Skeleton variant="rectangular" width={40} height={10} />
                        </p> : null}
            </div>
        </Box>
      </Box>

      <Box sx={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 10, backgroundColor: 'white' }}>
        <Input addChatItem={addChatItem} handleWaiting={handleWaiting}/>
      </Box>

      <div ref={divRef} />

    </div>
  );
}

export default App;
